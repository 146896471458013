import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import React, { lazy, Suspense, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthContext from '../components/AuthContext';
import DrawerContext from '../components/DrawerContext';
import SearchAppBar from '../components/SearchAppBar';
import {
  ADMIN_GROUP,
  ALT_USER_GROUP,
  APPBAR_HEIGHT,
  APPBAR_SMALL_HEIGHT,
  AUDIT_PDF_PATH,
  CLIENTS_ALL_PATH,
  CLIENTS_PATH,
  DASHBOARD_PATH,
  INSPECTION_FINALIZED_PATH,
  INSPECTION_NEW_PATH,
  INSPECTION_PATH,
  INSPECTION_PROPERTY_PATH,
  INSPECTOR_GROUP,
  INVENTORY_PATH,
  INVENTORY_PDF_PATH,
  MAP_PATH,
  NON_COMPLIANCE_REPORT_PATH,
  PLANNER_GROUP,
  PROPERTIES_PATH,
  PROPERTY_OWNER_GROUP,
  QUESTIONS_PATH,
  REGULATION_PATH,
  SHOW_ASSETS_PATH,
  SUPER_ADMIN_GROUP,
  USERS_ALL_PATH,
  USERS_PATH,
} from '../Constants';
import Loading from '../fhg/components/Loading';
import PrivilegeRoute from '../fhg/components/security/PrivilegeRoute';
import withRoot from '../withRoot';
import { MyTheme } from './App';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Map from './map/Map';
import ShowAssets from './show-assets/ShowAssets';
import useFullScreen from '../fhg/hooks/useFullScreen';

const FinalizedReport = lazy(() => import('./inspect/FinalizedReport'));
const NonComplianceReport = lazy(() => import('./inspect/NonComplianceReport'));
const DashboardView = lazy(() => import('./dashboard/DashboardView'));
const PropertyView = lazy(() => import('./property/PropertyView'));
const InspectionView = lazy(() => import('./inspect/InspectionView'));
const PropertyDetail = lazy(() => import('./inspect/PropertyDetail'));
const PropertyListView = lazy(() => import('./inspect/PropertyListView'));
const InventoryBuilder = lazy(() => import('./inventory/InventoryBuilder'));
const InventoryPdf = lazy(() => import('../reports/inventory/InventoryPdf'));
const SearchableRegulationLibrary = lazy(() => import('./inspect/SearchableRegulationLibrary'));
const Area = lazy(() => import('./master/AreaView'));
const UserView = lazy(() => import('./users/UserView'));
const MySignUp = lazy(() => import('./MySignUp'));
const AuditPdf = lazy(() => import('../reports/audit/AuditPdf'));
const ClientView = lazy(() => import('./clients/ClientView'));

export const materialTheme = {
  palette: {
    primary: {
      light: '#53C3d5',
      main: '#1796a4',
      dark: '#01636c',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#fbaa3e',
      main: '#ef7f22',
      dark: '#c35e0a',
    },
    background: {
      default: '#D3D3D3',
      paper: 'white',
      dark: '#5A5A5A',
    },
    text: {
      primary: '#1796a4',
      secondary: '#5A5A5A',
      disabled: '#D3D3D3',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButtonBase: {
      variant: 'contained',
    },
  },
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      // display: 'flex',
      overflow: 'hidden',
      height: '100%',
      fontFamily: theme.typography.fontFamily,
    },
    contentStyle: {
      width: '100%',
      // flex: '1 1',
      // flexGrow: 1,
      height: `calc(100% - ${APPBAR_HEIGHT}px)`,
      [theme.breakpoints.down('sm')]: {
        height: `calc(100% - ${APPBAR_SMALL_HEIGHT}px)`,
      },
      // height: '100%',
      // height: '100vh',
      // padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    toolbar: theme.mixins.toolbar,
  }),
  { name: 'MainStyles' },
);

function Main({ authState, authData }) {
  const classes = useStyles();
  const isFullScreen = useFullScreen();

  const groups = get(authData, 'signInUserSession.idToken.payload["cognito:groups"]', []);
  const cognitoSub = get(authData, 'signInUserSession.idToken.payload.sub', []);

  const isSuperAdmin = groups.indexOf(SUPER_ADMIN_GROUP) >= 0;
  const isAdmin = groups.indexOf(ADMIN_GROUP) >= 0;
  const isInspector = groups.indexOf(INSPECTOR_GROUP) >= 0;
  const isPlanner = groups.indexOf(PLANNER_GROUP) >= 0;
  const isManagement = isAdmin;
  const isAltUser = groups.indexOf(ALT_USER_GROUP) >= 0;

  //For now the Property Owners and Alt Users have identical privileges.
  const isPropertyOwner = groups.indexOf(PROPERTY_OWNER_GROUP) >= 0 || isAltUser;

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [showCreateAccount, setShowCreateAccount] = useState(false);

  const handleSignUp = () => {
    setShowCreateAccount(true);
  };

  const handleCloseSignUp = () => {
    setShowCreateAccount(false);
  };

  /**
   * When the user closes the sidebar, mark the sidebar closed.
   */
  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  /**
   * When the user opens the sidebar, mark the sidebar open.
   */
  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleSearch = () => {};

  let defaultPath;
  if (authState === 'signedIn') {
    if (isPropertyOwner) {
      defaultPath = INSPECTION_PATH;
    } else if (isPlanner) {
      defaultPath = DASHBOARD_PATH;
    } else if (isInspector) {
      defaultPath = INSPECTION_PATH;
    } else if (isAdmin) {
      defaultPath = USERS_ALL_PATH;
    } else if (isSuperAdmin) {
      defaultPath = CLIENTS_ALL_PATH;
    }

    return (
      <Suspense fallback={<Loading isLoading />}>
        <AuthContext.Provider
          value={{ isAdmin, isInspector, isPlanner, isManagement, isPropertyOwner, cognitoSub }}
        >
          <DrawerContext.Provider value={{ isDrawerOpen, onDrawerClose, onDrawerOpen }}>
            <Grid container dirction={'column'} className={classes.root}>
              <CssBaseline />
              {!isFullScreen && (
                <Switch>
                  <Route
                    exact
                    path={[
                      INSPECTION_FINALIZED_PATH,
                      NON_COMPLIANCE_REPORT_PATH,
                      INSPECTION_PROPERTY_PATH,
                      INSPECTION_NEW_PATH,
                      '/',
                      INVENTORY_PATH,
                      INVENTORY_PDF_PATH,
                      REGULATION_PATH,
                    ]}
                  >
                    <SearchAppBar
                      isDrawerOpen={isDrawerOpen}
                      showDrawer={false}
                      onDrawerOpen={onDrawerOpen}
                      userName={get(
                        authData,
                        'attributes.firstname',
                        get(authData, 'username', 'N/A'),
                      )}
                      showSearch={false}
                      onShowSignup={handleSignUp}
                      isSuperAdmin={isSuperAdmin}
                      isAdmin={isAdmin}
                      isPlanner={isPlanner}
                      isInspector={isInspector}
                      isPropertyOwner={isPropertyOwner}
                    />
                  </Route>
                  <Route
                    exact
                    path={[
                      REGULATION_PATH,
                      PROPERTIES_PATH,
                      INSPECTION_PATH,
                      QUESTIONS_PATH,
                      USERS_PATH,
                      CLIENTS_PATH,
                      MAP_PATH,
                      SHOW_ASSETS_PATH,
                      DASHBOARD_PATH,
                    ]}
                  >
                    <SearchAppBar
                      onSearch={handleSearch}
                      isDrawerOpen={isDrawerOpen}
                      onDrawerOpen={onDrawerOpen}
                      userName={get(
                        authData,
                        'attributes.firstname',
                        get(authData, 'username', 'N/A'),
                      )}
                      showSearch={true}
                      onShowSignup={handleSignUp}
                      isSuperAdmin={isSuperAdmin}
                      isAdmin={isAdmin}
                      isPlanner={isPlanner}
                      isInspector={isInspector}
                      isPropertyOwner={isPropertyOwner}
                    />
                  </Route>
                </Switch>
              )}
              <main className={classes.contentStyle}>
                <div className={classes.toolbar} />
                {showCreateAccount ? (
                  <Dialog open={true} maxWidth={'md'}>
                    <MySignUp theme={MyTheme} onClose={handleCloseSignUp} />
                  </Dialog>
                ) : (
                  <Switch>
                    <PrivilegeRoute
                      hasPrivilege={isAdmin || isInspector}
                      exact
                      path={INVENTORY_PATH}
                      component={InventoryBuilder}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isAdmin || isInspector}
                      exact
                      path={INVENTORY_PDF_PATH}
                      component={InventoryPdf}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isInspector || isPropertyOwner}
                      exact
                      path={AUDIT_PDF_PATH}
                      component={AuditPdf}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isAdmin}
                      exact
                      path={INSPECTION_FINALIZED_PATH}
                      component={FinalizedReport}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isAdmin}
                      exact
                      path={NON_COMPLIANCE_REPORT_PATH}
                      component={NonComplianceReport}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isInspector || isPropertyOwner}
                      exact
                      path={INSPECTION_PATH}
                      component={PropertyListView}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isInspector}
                      exact
                      path={INSPECTION_NEW_PATH}
                      component={InspectionView}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isInspector || isPropertyOwner}
                      exact
                      path={INSPECTION_PROPERTY_PATH}
                      component={PropertyDetail}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isPlanner}
                      exact
                      path={PROPERTIES_PATH}
                      component={PropertyView}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isAdmin || isInspector}
                      exact
                      path={DASHBOARD_PATH}
                      component={DashboardView}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isPlanner}
                      exact
                      path={QUESTIONS_PATH}
                      component={Area}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isAdmin}
                      exact
                      path={USERS_PATH}
                      component={UserView}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isSuperAdmin}
                      exact
                      path={CLIENTS_PATH}
                      component={ClientView}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isPlanner}
                      exact
                      path={MAP_PATH}
                      component={Map}
                    />
                    <PrivilegeRoute
                      hasPrivilege={isPlanner}
                      exact
                      path={SHOW_ASSETS_PATH}
                      component={ShowAssets}
                    />

                    <PrivilegeRoute
                      hasPrivilege={isInspector}
                      path={REGULATION_PATH}
                      component={SearchableRegulationLibrary}
                    />
                    {defaultPath ? (
                      <Route path="/" render={() => <Redirect to={defaultPath} />} />
                    ) : (
                      <Typography variant={'h6'} style={{ margin: 32 }}>
                        You do not have permission to view this page. Contact your administrator to
                        get permission.
                      </Typography>
                    )}
                  </Switch>
                )}
              </main>
            </Grid>
          </DrawerContext.Provider>
        </AuthContext.Provider>
      </Suspense>
    );
  } else {
    return null;
  }
}

export default withRoot(materialTheme, Main);
