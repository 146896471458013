import DateFnsUtils from '@date-io/date-fns';
import { useTheme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import gql from 'graphql-tag';
import {
  castArray,
  filter,
  reject,
  isObject,
  find,
  isNumber,
  isArray,
  map,
  sortBy,
  toLower
} from 'lodash';
import get from 'lodash/get';
import React, {
  useState,
  useMemo,
  Fragment,
  useEffect,
  useContext
} from 'react';
import TextField from '../../components/TextField';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import ChangedContext from '../../fhg/components/edit/ChangedContext';
import Prompt from '../../fhg/components/edit/Prompt';
import useEditData from '../../fhg/components/edit/useEditData';
import Form from '../../fhg/components/Form';
import Grid from '../../fhg/components/Grid';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import ProgressButton from '../../fhg/components/ProgressButton';
import ReactSelect, { DELETE_ACTION } from '../../fhg/components/ReactSelect';
import Typography from '../../fhg/components/Typography';
import FHGTypography from '../../fhg/components/Typography';
import { toNumber, toString } from '../../fhg/utils/Utils';
import AreaEdit from '../master/AreaEdit';
import EquipmentEdit from '../master/EquipmentEdit';

export const GENERAL_TYPE_ID = 1;
export const FIELD_TYPE_ID = 2;
export const PLAYGROUND_TYPE_ID = 3;
export const COURT_TYPE_ID = 4;

export const GENERAL_TYPE = GENERAL_TYPE_ID.toString();
export const FIELD_TYPE = FIELD_TYPE_ID.toString();
export const PLAYGROUND_TYPE = PLAYGROUND_TYPE_ID.toString();
export const COURT_TYPE = COURT_TYPE_ID.toString();

const useStyles = makeStyles(theme => ({
    formStyle: {
      width: '100%',
      padding: theme.spacing(1),
    },
    buttonPanelStyle: {
      position: 'sticky',
      bottom: 0,
      zIndex: 100,
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  }),
  { name: 'GeneralItemEditStyles' }
);

const COLOR_FRAGMENT = gql`
  fragment colorInfo on Color {
    id
    value: id
    isDeleted
    name
    label: name
    isDeleted
  }
`;

const COLOR_INVENTORY_QUERY = gql`
  query getColorForInventory {
    color: color_All {
      ...colorInfo
    }
  }
  ${COLOR_FRAGMENT}
`;

export const COLOR_DELETE = gql`
  mutation colorDelete($id: Int!) {
    color_Delete(colorId: $id)
  }
`;

const COLOR_CREATE = gql`
  mutation colorCreate($name: String!) {
    color: color_Create(color: { name: $name }) {
      ...colorInfo
    }
  }
  ${COLOR_FRAGMENT}
`;

const AGE_APPROPRIATENESS_FRAGMENT = gql`
  fragment ageAppropriatenessInfo on AgeAppropriateness {
    id
    value: id
    name
    label: name
    isDeleted
  }
`;

export const AGE_DELETE = gql`
  mutation ageDelete($id: Int!) {
    ageAppropriateness_Delete(ageAppropriatenessId: $id)
  }
`;

const AGE_CREATE = gql`
  mutation ageCreate($name: String!) {
    ageAppropriateness: ageAppropriateness_Create(
      ageAppropriateness: { name: $name }
    ) {
      ...ageAppropriatenessInfo
    }
  }
  ${AGE_APPROPRIATENESS_FRAGMENT}
`;

const AGE_APPROPRIATENESS_INVENTORY_QUERY = gql`
  query getAgeAppropriatenessForInventory {
    ageAppropriateness: ageAppropriateness_All {
      ...ageAppropriatenessInfo
    }
  }
  ${AGE_APPROPRIATENESS_FRAGMENT}
`;

const AREA_FRAGMENT = gql`
  fragment areaInventoryInfo on Area {
    id
    value: id
    name
    label: name
    isDeleted
    isCategory
    templateId
  }
`;

const EQUIPMENT_FRAGMENT = gql`
  fragment equipmentInventoryInfo on Equipment {
    id
    value: id
    areaId
    isDeleted
    name
    label: name
    isEntity
    manufacturerId
    modelId
  }
`;

export const AREA_DELETE = gql`
  mutation areaDelete($id: Int!) {
    area_Delete(areaId: $id)
  }
`;

const AREA_CREATE = gql`
  mutation areaCreate($name: String!, $isCategory: Boolean, $templateId: Int) {
    area: area_Create(
      area: { name: $name, isCategory: $isCategory, templateId: $templateId }
    ) {
      ...areaInventoryInfo
    }
  }
  ${AREA_FRAGMENT}
`;

export const EQUIPMENT_DELETE = gql`
  mutation equipmentDeleteForInventoryItem($id: Int!) {
    equipment_Delete(equipmentId: $id)
  }
`;

const EQUIPMENT_CREATE = gql`
  mutation equipmentCreateForInventoryItem($name: String!, $isEntity: Boolean, $areaId: Int!) {
    equipment: equipment_Create(
      equipment: { name: $name, isEntity: $isEntity, areaId: $areaId }
    ) {
      ...equipmentInventoryInfo
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const MASTER_AREAS_INVENTORY_QUERY = gql`
  query getMasterAreasForInventory {
    masterAreas: area_AllMasterTemplate {
      ...areaInventoryInfo
    }
  }
  ${AREA_FRAGMENT}
`;

export const MASTER_EQUIPMENT_INVENTORY_QUERY = gql`
  query getMasterEquipmentForInventory {
    masterEquipment: equipment_AllMasterTemplate {
      ...equipmentInventoryInfo
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const MASTER_EQUIPMENT_REDUCED_INVENTORY_QUERY = gql`
  query getMasterEquipmentForInventory {
    masterEquipment: equipment_AllMasterTemplateReduced {
      ...equipmentInventoryInfo
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

export const INVENTORY_ITEM_FRAGMENT = gql`
  fragment inventoryItemInfo on InventoryItem {
    id
    ageApp {
      id
      name
      isDeleted
    }
    ageAppropriatenessId
    area {
      id
      name
    }
    areaId
    colorList {
      id
      name
    }
    colorIdList
    description
    equipment {
      id
      name
    }
    equipmentId
    fieldNumber
    installDate
    inventoryType {
      id
      name
    }
    inventoryTypeId
    isDeleted
    manufacturer {
      id
      name
    }
    manufacturerId
    model {
      id
      modelNumber
    }
    modelId
    name
    quantity
    propertyId
    createdDateTime
  }
`;

export const INVENTORY_PROPERTY_QUERY = gql`
  query getInventoryPropertyQuery($propertyId: Int!) {
    inventoryItems: inventoryItem_AllWhere(
      inventoryItemSearch: { propertyId: [$propertyId, null] }
    ) {
      ...inventoryItemInfo
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
`;

// Update the inventoryItem with the given properties.
const INVENTORY_ITEM_CREATE = gql`
  mutation InventoryItemCreate(
    $ageAppropriateness: String
    $ageAppropriatenessId: Int
    $areaId: Int
    $colorIdList: [Int]
    $description: String
    $equipmentId: Int
    $fieldNumber: String
    $installDate: String
    $inventoryTypeId: Int
    $manufacturerId: Int
    $modelId: Int
    $name: String
    $quantity: Int
    $propertyId: Int!
  ) {
    inventoryItem: inventoryItem_Create(
      inventoryItem: {
        ageAppropriateness: $ageAppropriateness
        ageAppropriatenessId: $ageAppropriatenessId
        areaId: $areaId
        colorIdList: $colorIdList
        description: $description
        equipmentId: $equipmentId
        fieldNumber: $fieldNumber
        installDate: $installDate
        inventoryTypeId: $inventoryTypeId
        manufacturerId: $manufacturerId
        modelId: $modelId
        name: $name
        quantity: $quantity
        propertyId: $propertyId
      }
    ) {
      ...inventoryItemInfo
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
`;

// Update the inventoryItem with the given properties.
const INVENTORY_ITEM_UPDATE = gql`
  mutation InventoryItemUpdate(
    $inventoryItemId: Int!
    $ageAppropriateness: String
    $ageAppropriatenessId: Int
    $areaId: Int
    $colorIdList: [Int]
    $description: String
    $equipmentId: Int
    $fieldNumber: String
    $installDate: String
    $inventoryTypeId: Int
    $manufacturerId: Int
    $modelId: Int
    $name: String
    $quantity: Int
    $propertyId: Int!
  ) {
    inventoryItem: inventoryItem_Update(
      inventoryItemId: $inventoryItemId,
      inventoryItem: {
        ageAppropriateness: $ageAppropriateness
        ageAppropriatenessId: $ageAppropriatenessId
        areaId: $areaId
        colorIdList: $colorIdList
        description: $description
        equipmentId: $equipmentId
        fieldNumber: $fieldNumber
        installDate: $installDate
        inventoryTypeId: $inventoryTypeId
        manufacturerId: $manufacturerId
        modelId: $modelId
        name: $name
        quantity: $quantity
        propertyId: $propertyId
      }
    ) {
      ...inventoryItemInfo
    }
  }
  ${INVENTORY_ITEM_FRAGMENT}
`;

/**
 * Component to edit the individual item.
 *
 * Reviewed: 12/18/2020
 */
export default function InventoryItemEdit({
  property = {},
  inventoryItem,
  onSave
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { setIsChildChanged } = useContext(ChangedContext);

  const [inventoryItemCreate] = useMutationFHG(INVENTORY_ITEM_CREATE);
  const [inventoryItemUpdate] = useMutationFHG(INVENTORY_ITEM_UPDATE);

  const {
    loading: loadingColor,
    data: dataColor,
    statusComponent: statusComponentColor
  } = useQueryFHG(COLOR_INVENTORY_QUERY, { fetchPolicy: 'cache-and-network' });
  const colorOptions = useMemo(() => {
    const colors = get(dataColor, 'color') || [];
    return sortBy(colors, 'name');
  }, [dataColor]);

  const [
    colorDelete,
    deleteColorStatusComponent,
    { loading: loadingColorDelete }
  ] = useMutationFHG(COLOR_DELETE);
  const [
    colorCreate,
    createColorStatusComponent,
    { loading: loadingColorCreate }
  ] = useMutationFHG(COLOR_CREATE);

  const [ageDelete, deleteAgeStatusComponent, { loading: loadingAgeDelete }] =
    useMutationFHG(AGE_DELETE);
  const [ageCreate, createAgeStatusComponent, { loading: loadingAgeCreate }] =
    useMutationFHG(AGE_CREATE);

  const [
    areaDelete,
    deleteAreaStatusComponent,
    { loading: loadingAreaDelete }
  ] = useMutationFHG(AREA_DELETE);
  const [
    areaCreate,
    createAreaStatusComponent,
    { loading: loadingAreaCreate }
  ] = useMutationFHG(AREA_CREATE);

  const [
    equipmentDelete,
    deleteEquipmentStatusComponent,
    { loading: loadingEquipmentDelete }
  ] = useMutationFHG(EQUIPMENT_DELETE);
  const [
    equipmentCreate,
    createEquipmentStatusComponent,
    { loading: loadingEquipmentCreate }
  ] = useMutationFHG(EQUIPMENT_CREATE);

  const {loading: loadingAge, data: dataAge, statusComponent: statusComponentAge} = useQueryFHG(
     AGE_APPROPRIATENESS_INVENTORY_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const ageAppropriatenessOptions = useMemo(() => {
    const ageOptions = get(dataAge, 'ageAppropriateness') || [];
    return sortBy(ageOptions, 'name');
  }, [dataAge]);

  const {
    loading: loadingMasterAreas,
    data: dataMasterAreas = [],
    statusComponent: statusComponentMasterAreas
  } = useQueryFHG(
     MASTER_AREAS_INVENTORY_QUERY, {fetchPolicy: 'cache-and-network'});

  const areaOptions = get(dataMasterAreas, 'masterAreas') || [];

  const {
    loading: loadingMasterEquipment,
    data: dataMasterEquipment = [],
    statusComponent: statusComponentMasterEquipment
  } = useQueryFHG(MASTER_EQUIPMENT_INVENTORY_QUERY, {fetchPolicy: 'cache-and-network'});

  const [
    editValues,
    handleEditChange,
    {
      setEditValues,
      isChanged,
      defaultValues,
      handleSelectChange,
      setDefaultValues,
    }
  ] = useEditData(inventoryItem, [
    'id',
    'inventoryTypeId',
    'equipmentId',
    'areaId'
  ]);

  const colorValues = useMemo(
    () =>
      ReactSelect.createValueFromIdList(editValues.colorIdList, colorOptions),
    [colorOptions, editValues.colorIdList]
  );
  const colorDefaultValues = useMemo(
    () =>
      ReactSelect.createValueFromIdList(
        defaultValues.colorIdList,
        colorOptions
      ),
    [colorOptions, defaultValues.colorIdList]
  );

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const [showEquipmentEdit, setShowEquipmentEdit] = useState(false);
  const [showAreaEdit, setShowAreaEdit] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const equipmentOptions = useMemo(() => {
    const areaId =
      (editValues.areaId && editValues.areaId.id) ||
      editValues.areaId ||
      defaultValues.areaId;
    return filter(get(dataMasterEquipment, 'masterEquipment') || [], {
      areaId
    });
  }, [dataMasterEquipment, editValues.areaId, defaultValues.areaId]);

  /**
   * Update isSaving for all the loading states that should disable the UI.
   */
  useEffect(() => {
    setIsSaving(
      loadingColorCreate ||
        loadingColorDelete ||
        loadingAgeCreate ||
        loadingAgeDelete ||
        loadingAreaCreate ||
        loadingAreaDelete ||
        loadingEquipmentCreate ||
        loadingEquipmentDelete
    );
  }, [
    loadingColorCreate,
    loadingColorDelete,
    loadingAgeCreate,
    loadingAgeDelete,
    loadingAreaCreate,
    loadingAreaDelete,
    loadingEquipmentCreate,
    loadingEquipmentDelete
  ]);

  /**
   * Auto set area to a playground area if there is only one when playground radio is set.
   */
  useEffect(() => {
    if (
      areaOptions &&
      areaOptions.length > 0 &&
      editValues.inventoryTypeId &&
      !editValues.areaId &&
      !defaultValues.areaId
    ) {
      if (+editValues.inventoryTypeId === PLAYGROUND_TYPE_ID) {
        const areas = filter(areaOptions, area =>
          new RegExp('\\bplayground\\b').test(toLower(area.name))
        );
        if (areas.length === 1) {
          setEditValues({ ...editValues, areaId: areas[0] });
        }
      }
    }
  }, [editValues.areaId, editValues.inventoryTypeId, areaOptions]);

  /**
   * If the equipment options change due to a refetch or area selection changing, set equipment ID. If an area is
   * selected and there is only one option, it will be auto selected.
   */
  useEffect(() => {
    if (equipmentOptions && equipmentOptions.length > 0) {
      if (editValues.equipmentId) {
        const id = isNumber(editValues.equipmentId)
          ? editValues.equipmentId
          : get(editValues, 'equipmentId[0].id');
        const equipment = find(equipmentOptions, { id });

        if (!equipment) {
          //If an area is selected and there is only one option, auto select the equipment ID.
          const autoEquipment =
            editValues.areaId && equipmentOptions.length === 1
              ? equipmentOptions[0]
              : null;

          setEditValues({ ...editValues, equipmentId: autoEquipment });
        }
      } else if (editValues.areaId && equipmentOptions.length === 1) {
        setEditValues({
          ...editValues,
          equipmentId: get(equipmentOptions, '[0]')
        });
      }
    }
  }, [setEditValues, equipmentOptions]);

  /**
   * When the inventory item changes reset the default value.
   */
  useEffect(() => {
    if (inventoryItem) {
      setDefaultValues(inventoryItem);
    }
  }, [inventoryItem, setDefaultValues]);

  /**
   * Get the selected Area from the editValues. The areaId can be in 3 forms, 1)Array with one item, 2) the area, 3) The
   * areaId as an actual id number. If the areaId is a number, the id is used to lookup the area from the areaOptions.
   *
   * @return {*|{}}The area or undefined.
   */
  const getArea = () => {
    const temp = get(editValues, 'areaId[0]', get(editValues, 'areaId'));
    return (isNumber(temp) ? find(areaOptions, { id: temp }) : temp) || {};
  };

  /**
   * Get the selected Equipment from the editValues. The equipmentId can be in 3 forms, 1)Array with one item, 2) the
   * equipment, 3) The equipmentId as an actual id number. If the equipmentId is a number, the id is used to lookup the
   * equipment from the equipmentOptions.
   *
   * @return {*|{}}The equipment or undefined.
   */
  const getEquipment = () => {
    const temp = get(
      editValues,
      'equipmentId[0]',
      get(editValues, 'equipmentId')
    );
    return (isNumber(temp) ? find(equipmentOptions, { id: temp }) : temp) || {};
  };

  /**
   * If an area is selected and there isn't an inventory name, auto generate the inventory name.
   */
  useEffect(() => {
    const area = getArea();
    const equipment = getEquipment();

    if (area.value && equipment.value) {
      if (!defaultValues.name && !editValues.name) {
        const areaName = area.name;
        const useName = equipment.name || areaName;
        setEditValues({
          ...editValues,
          name: useName === 'General' ? areaName : useName
        });

        //Auto focus and select the auto generated name, so the user can immediately type to replace it.
        setTimeout(() => {
          const elements = document.getElementsByName('name');
          if (elements && elements.length > 0) {
            elements[0].focus();
            elements[0].select();
          }
        }, 300);
      }
    }
  }, [
    defaultValues.name,
    editValues.equipmentId,
    editValues.areaId,
    areaOptions,
    equipmentOptions
  ]);

  /**
   * When a change occurs, handle the edit change and set the child changed property.
   *
   * @param event The changed event.
   * @param value The value of the event.
   * @param reason The reason of the change.
   * @param newValue The new value.
   * @param name The name of the property changed.
   */
  const handleChange = (event, value, reason, newValue, name) => {
    handleEditChange(event, value, reason, newValue, name);
    setIsChildChanged(true);
  };

  /**
   * Get the value from the select where a new value could be created.
   *
   * @param idProperty The ID property of the select.
   * @param baseProperty The main property of the select.
   * @param isMulti Indicates if the idProperty is a list. If it is, the result will be a list of Ids.
   * @return {{}} The value(s) for the id(s)
   */
  const getCreatableSelect = (idProperty, baseProperty, isMulti = false) => {
    const result = {};

    if (editValues[idProperty] !== undefined) {
      if (isMulti) {
        result[baseProperty] = map(editValues[idProperty], 'id');
      } else {
        let id = get(editValues, `${idProperty}.id`);
        if (id === undefined) {
          result[baseProperty] = editValues[idProperty].value;
        } else {
          result[idProperty] = id;
        }
      }
      delete editValues[idProperty];
    }

    return result;
  };

  /*
   * The refetch queries to update the lists that change when a mutation occurs.
   */
  const getRefetchQueries = () => [{ query: COLOR_INVENTORY_QUERY }];
  const getRefetchInventoryItemsQueries = () => [
    {
      query: INVENTORY_PROPERTY_QUERY,
      variables: { propertyId: property.id }
    }
  ];
  const getRefetchAgeQueries = () => [{ query: AGE_APPROPRIATENESS_INVENTORY_QUERY }];
  const getRefetchAreaQueries = () => [{ query: MASTER_AREAS_INVENTORY_QUERY }];
  const getRefetchEquipmentQueries = () => [{ query: MASTER_EQUIPMENT_INVENTORY_QUERY }];

  /**
   * Delete the color that was selected.
   *
   * @param deleteOption The color to delete.
   */
  const handleColorDelete = (deleteOption) => {
    const deleteItems = castArray(deleteOption);

    for (const deleteItem of deleteItems) {
      colorDelete({variables: { id: deleteItem.id }, refetchQueries: getRefetchQueries});
    }
    setEditValues({ ...editValues, colorId: [] });
  };

  /**
   * Delete the age appropriateness selected.
   *
   * @param deleteOption The age appropriateness to delete.
   */
  const handleAgeDelete = (deleteOption) => {
    const id = get(deleteOption, '[0].id');

    if (id) {
      ageDelete({ variables: { id }, refetchQueries: getRefetchAgeQueries });
    } else {
      console.log('Age doesn\'t have a valid id.');
    }

    setEditValues({ ...editValues, ageAppropriatenessId: undefined });
  };

  /**
   * Delete the area selected.
   *
   * @param deleteOption The area to delete.
   */
  const handleAreaDelete = (deleteOption) => {
    const areaToDelete = castArray(deleteOption);
    const id = get(areaToDelete, '[0].id');

    areaDelete({ variables: { id }, refetchQueries: getRefetchAreaQueries });

    setEditValues({ ...editValues, areaId: undefined });
  };

  /**
   * Delete the equipment selected.
   *
   * @param deleteOption The equipment to delete.
   */
  const handleEquipmentDelete = (deleteOption) => {
    const equipmentToDelete = castArray(deleteOption);
    const id = get(equipmentToDelete, '[0].id');

    if (id !== undefined) {
      equipmentDelete({
        variables: { id },
        refetchQueries: getRefetchEquipmentQueries
      });
      setEditValues({ ...editValues, equipmentId: undefined });
    }
  };

  /**
   * Show the edit for the area selected.
   *
   * @param area The area to edit.
   */
  const handleAreaEdit = (area) => {
    if (area) {
      if (isArray(area)) {
        if (area.length === 1) {
          setShowAreaEdit(area[0]);
        }
      } else {
        setShowAreaEdit(area);
      }
    }
  };

  /**
   * Show the edit for the equipment selected. The parameter is a list, but only one is selected and so the edit is
   * for the first item.
   *
   * @param equipmentList The equipment list to edit.
   */
  const handleEquipmentEdit = (equipmentList) => {
    if (equipmentList && equipmentList.length > 0) {
      setShowEquipmentEdit(equipmentList[0]);
    }
  };

  /**
   * Can the area be deleted or edited.
   *
   * @param area The area to check.
   * @param action The action to check
   * @return {*|boolean} Indicates if the action can be performed on the selected area.
   */
  const handleCanAreaAction = (area, action) => {
    const path = isArray(area) ? '[0].isCategory' : 'isCategory';
    return action === DELETE_ACTION ? get(area, path) : true;
  };

  /**
   * Can the equipment be deleted or edited.
   *
   * @param equipment The equipment to check.
   * @param action The action to check
   * @return {*|boolean} Indicates if the action can be performed on the selected equipment.
   */
  const handleCanEquipmentAction = (equipment, action) => {
    const path = isArray(equipment) ? '[0].isEntity' : 'isEntity';
    return action === DELETE_ACTION ? get(equipment, path) : true;
  };

  /**
   * Handle onSubmit for the form. Mutates the database object with the changes.
   */
  const handleSubmit = async () => {
    if (isChanged) {
      try {
        setIsSaving(true);
        const areaId = get(
          editValues,
          'areaId[0].id',
          get(editValues, 'areaId.id')
        );
        const equipmentId = get(
          editValues,
          'equipmentId[0].id',
          get(editValues, 'equipmentId.id')
        );
        const colorIdList = getCreatableSelect(
          'colorIdList',
          'colorIdList',
          true
        );
        const ageAppropriateness = getCreatableSelect(
          'ageAppropriatenessId',
          'ageAppropriateness'
        );

        delete editValues.area;
        const variables = {
          propertyId: property.id,
          ...editValues,
          ...colorIdList,
          ...ageAppropriateness,
          inventoryItemId: editValues.id,
          areaId,
          equipmentId,
          inventoryTypeId:
            toNumber(editValues.inventoryTypeId) || GENERAL_TYPE_ID,
          areaId_Edit: undefined,
          inventoryTypeId_Edit: undefined,
          equipmentId_Edit: undefined,
          colorIdList_Edit: undefined,
          ageAppropriatenessId_Edit: undefined,
        };

        if (defaultValues.id) {
          await inventoryItemUpdate({
            variables,
            refetchQueries: getRefetchInventoryItemsQueries
          });
        } else {
          await inventoryItemCreate({
            variables,
            refetchQueries: getRefetchInventoryItemsQueries
          });
        }

        setIsSaving(false);
        setIsChildChanged(false);
        onSave && onSave();
      } catch (e) {
        setIsSaving(false);
      }
    }
  };

  /**
   * Handle the select changes. If the action is a create, create the item. If the action is select, select the item.
   * All custom edit changes are passed through and ignored.
   *
   * @param event The select event.
   * @param name The name of the selected component.
   * @param isMulti Indicates if the component is a multi select.
   * @param options The option for the action.
   *
   * @return {Promise<void>} The promise for the select action.
   */
  const handleSelectChanges = async (event, name, isMulti, options) => {
    let useEvent;
    let useName = name;

    if (options && options.action === 'create-option') {
      let newValues = filter(isMulti ? event : castArray(event.value), {
        __isNew__: true
      });
      useEvent = isMulti ? reject(event, { __isNew__: true }) : event;

      for (const newValue of newValues) {
        const result = await colorCreate({
          variables: { name: newValue.value },
          refetchQueries: getRefetchQueries
        });
        const value = Object.values(result.data);

        if (value && value.length > 0) {
          if (isMulti) {
            useEvent.push(Object.values(result.data)[0]);
          } else {
            useEvent = Object.values(result.data)[0];
          }
        }
      }
    } else if (
      options &&
      (options.action === 'remove-value' || options.action === 'select-option')
    ) {
      useEvent = event;
    } else {
      // Cannot use the same name as the component otherwise the list is removed.
      useName = name + '__Edit';
    }

    handleSelectChange(useEvent, useName || (options && options.name));
  };

  /**
   * Handle the select changes. If the action is a create, create the item. If the action is select, select the item.
   * All custom edit changes are passed through and ignored.
   *
   * @param event The select event.
   * @param name The name of the selected component.
   * @param options The option for the action.
   * @param create The create graphQL function to create the item.
   * @param variables The variables for the graphQL function.
   * @param refetchQueries The function to refetch after the graphQL function.
   *
   * @return {Promise<void>} The promise for the select action.
   */
  const handleSelectSingleChange = async (
    event,
    name,
    options,
    create,
    variables,
    refetchQueries
  ) => {
    let useEvent = event;
    if (options && options.action === 'create-option') {
      const variablesLocal = { ...variables, name: useEvent.value };
      const result = await create({
        variables: variablesLocal,
        refetchQueries
      });
      const value = Object.values(result.data);

      if (value && value.length > 0) {
        useEvent = value;
      }
    }

    handleSelectChange(useEvent, name || (options && options.name));
  };

  /**
   * Handle the age select changes.
   *
   * @param event The select event.
   * @param name The name of the selected component.
   * @param isMulti Indicates if the select is a multi select.
   * @param options The option for the action.
   *
   * @return {Promise<void>} The promise for the select action.
   */
  const handleSelectAgeChange = (event, name, isMulti, options) => {
    if (!isMulti) {
      handleSelectSingleChange(
        event,
        name,
        options,
        ageCreate,
        undefined,
        getRefetchAgeQueries
      );
    } else {
      console.log('Cannot handle multiple ages');
    }
  };

  /**
   * Handle the area select changes.
   *
   * @param event The select event.
   * @param name The name of the selected component.
   * @param isMulti Indicates if the select is a multi select.
   * @param options The option for the action.
   *
   * @return {Promise<void>} The promise for the select action.
   */
  const handleSelectAreaChange = async (event, name, isMulti, options) => {
    if (!isMulti) {
      await handleSelectSingleChange(
        event,
        name,
        options,
        areaCreate,
        { templateId: 1, isCategory: true },
        getRefetchAreaQueries
      );
    } else {
      console.log('Cannot handle multiple areas');
    }
  };

  /**
   * Handle the equipment select changes.
   *
   * @param event The select event.
   * @param name The name of the selected component.
   * @param isMulti Indicates if the select is a multi select.
   * @param options The option for the action.
   *
   * @return {Promise<void>} The promise for the select action.
   */
  const handleSelectEquipmentChange = async (event, name, isMulti, options) => {
    if (!isMulti) {
      const areaId =
        (isObject(editValues.areaId)
          ? get(editValues, 'areaId.id', get(editValues, 'areaId[0].id'))
          : editValues.areaId) || defaultValues.areaId;
      await handleSelectSingleChange(
        event,
        name,
        options,
        equipmentCreate,
        { areaId, isEntity: true },
        getRefetchEquipmentQueries
      );
    } else {
      console.log('Cannot handle multiple equipment');
    }
  };

  /**
   * Close the Equipment edit dialog.
   */
  const closeEditEquipment = () => {
    setShowEquipmentEdit(false);
  };

  /**
   * Close the Equipment edit dialog.
   */
  const closeEditArea = () => {
    setShowAreaEdit(false);
  };

  /**
   * Callback for the submit success. Update the id property for the area.
   *
   * @param result The result of the submit.
   */
  const handleAreaSubmitSuccess = (result) => {
    const area = result.data.area;

    setEditValues({
      ...editValues,
      areaId: { ...area, label: area.name, value: area.id }
    });
  };

  /**
   * Callback for the submit success. Update the id property for the equipment.
   *
   * @param equipment The equipment result of the submit.
   */
  const handleEquipmentSubmitSuccess = (equipment) => {
    setEditValues({ ...editValues, equipmentId: equipment.equipment });
  };

  /**
   * Find the area object that is selected.
   */
  const selectedArea = useMemo(() => {
    const areaId = editValues.areaId || defaultValues.areaId;

    if (isNumber(areaId)) {
      return find(areaOptions, { id: areaId });
    } else if (isArray(areaId)) {
      return areaId.length > 0 ? areaId[0] : undefined;
    } else {
      return areaId;
    }
    // return isNumber(areaId) ? find(areaOptions, {id: areaId}): areaId;
  }, [areaOptions, editValues.areaId, defaultValues.areaId]);

  const useInventoryTypeId = toString(
    editValues.inventoryTypeId !== undefined
      ? editValues.inventoryTypeId
      : defaultValues.inventoryTypeId || GENERAL_TYPE_ID
  );
  const isField = useInventoryTypeId === FIELD_TYPE;
  const isPlayground = useInventoryTypeId === PLAYGROUND_TYPE;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Prompt when={isChanged} />
      {statusComponentColor ||
        statusComponentAge ||
        deleteColorStatusComponent ||
        deleteAgeStatusComponent ||
        deleteAreaStatusComponent ||
        deleteEquipmentStatusComponent ||
        createColorStatusComponent ||
        createAgeStatusComponent ||
        createAreaStatusComponent ||
        createEquipmentStatusComponent ||
        statusComponentMasterEquipment ||
        (!loadingMasterAreas && statusComponentMasterAreas)}
      {showEquipmentEdit && (
        <EquipmentEdit
          onClose={closeEditEquipment}
          area={selectedArea}
          equipment={showEquipmentEdit}
          onSuccess={handleEquipmentSubmitSuccess}
        />
      )}
      {showAreaEdit && (
        <AreaEdit
          onClose={closeEditArea}
          isCreate={false}
          area={selectedArea}
          onSuccess={handleAreaSubmitSuccess}
          isInventory
        />
      )}
      <Grid
        name={'Inventory Item Root'}
        container
        item
        resizable
        style={{ maxHeight: `calc(100% - ${theme.spacing(7)}px)` }}
        fullHeight
        isScrollable
        wrap={'nowrap'}
      >
        <Grid item fullWidth overflow={'visible'}>
          <Form
            submit={!isSelectOpen ? handleSubmit : undefined}
            className={classes.formStyle}
          >
            <Grid container spacing={2} alignItems={'flex-start'}>
              <Grid item fullWidth xs={12} md={6} lg={4}>
                <FormControl
                  component='fieldset'
                  style={{
                    marginTop: theme.spacing(1),
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    width: '100%'
                  }}
                >
                  <FormLabel component='legend'>
                    <Typography id={'inventory.type.label'} />
                  </FormLabel>
                  <RadioGroup
                    aria-label='inventory type'
                    name='inventoryTypeId'
                    value={useInventoryTypeId}
                    row
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={GENERAL_TYPE}
                      control={
                        <Radio style={{ padding: theme.spacing(0.5) }} />
                      }
                      label={<Typography id={'inventory.general.label'} />}
                    />
                    <FormControlLabel
                      value={PLAYGROUND_TYPE}
                      control={
                        <Radio style={{ padding: theme.spacing(0.5) }} />
                      }
                      label={<Typography id={'inventory.playground.label'} />}
                    />
                    <FormControlLabel
                      value={FIELD_TYPE}
                      control={
                        <Radio style={{ padding: theme.spacing(0.5) }} />
                      }
                      label={<Typography id={'inventory.field.label'} />}
                    />
                    <FormControlLabel
                      value={COURT_TYPE}
                      control={
                        <Radio style={{ padding: theme.spacing(0.5) }} />
                      }
                      label={<Typography id={'inventory.court.label'} />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item fullWidth xs={12} sm={6} lg={4}>
                <ReactSelect
                  name={'areaId'}
                  label={
                    <FHGTypography
                      display={'inline'}
                      id={'inventory.area.label'}
                    />
                  }
                  options={areaOptions}
                  defaultValue={ReactSelect.createObjectValue(
                    defaultValues.areaId,
                    areaOptions
                  )}
                  value={ReactSelect.createObjectValue(
                    editValues.areaId,
                    areaOptions
                  )}
                  hideSelectedOptions
                  onMenuOpen={() => setIsSelectOpen(true)}
                  onMenuClose={() => setIsSelectOpen(false)}
                  isLoading={loadingMasterAreas}
                  onChange={handleSelectAreaChange}
                  isMulti={false}
                  isCreatable
                  isConfirmDeletable
                  onCanDoAction={handleCanAreaAction}
                  isEditable={!loadingMasterAreas}
                  isClearable
                  onEditChange={handleAreaEdit}
                  onDelete={handleAreaDelete}
                  openMenuOnClick={false}
                  fullWidth
                  disabled={isSaving}
                />
              </Grid>
              <Grid item fullWidth xs={12} sm={6} md={4} lg={4}>
                <ReactSelect
                  name={'equipmentId'}
                  label={
                    <FHGTypography
                      display={'inline'}
                      id={
                        isPlayground
                          ? 'inventory.equipment.label'
                          : 'inventory.item.label'
                      }
                    />
                  }
                  options={equipmentOptions}
                  defaultValue={ReactSelect.createObjectValue(
                    defaultValues.equipmentId,
                    equipmentOptions
                  )}
                  value={ReactSelect.createObjectValue(
                    editValues.equipmentId,
                    equipmentOptions
                  )}
                  hideSelectedOptions
                  onMenuOpen={() => setIsSelectOpen(true)}
                  onMenuClose={() => setIsSelectOpen(false)}
                  isLoading={loadingMasterEquipment}
                  onChange={handleSelectEquipmentChange}
                  helperText={
                    !editValues.areaId
                      ? 'Select an area to add equipment.'
                      : undefined
                  }
                  isMulti={false}
                  isCreatable
                  isConfirmDeletable
                  onCanDoAction={handleCanEquipmentAction}
                  isEditable={!loadingMasterEquipment}
                  isClearable
                  onEditChange={handleEquipmentEdit}
                  onDelete={handleEquipmentDelete}
                  openMenuOnClick={false}
                  fullWidth
                  margin='none'
                  disabled={isSaving || !editValues.areaId}
                />
              </Grid>
              <Grid item fullWidth xs={12} sm={6} md={4}>
                <TextField
                  name={'name'}
                  labelKey={'inventory.name.label'}
                  defaultValue={get(defaultValues, 'name')}
                  value={editValues.name}
                  onChange={handleChange}
                  disabled={isSaving}
                  fullWidth
                  required
                  margin={'none'}
                />
              </Grid>
              {!isField ? (
                <Grid item fullWidth xs={12} sm={6} md={4}>
                  <TextField
                    name={'quantity'}
                    type={'number'}
                    labelKey={'inventory.quantity.label'}
                    defaultValue={get(defaultValues, 'quantity')}
                    value={editValues.quantity}
                    onChange={handleChange}
                    disabled={isSaving}
                    fullWidth
                    required
                    margin={'none'}
                  />
                </Grid>
              ) : (
                <Grid item fullWidth xs={12} sm={6} md={4}>
                  <TextField
                    name={'fieldNumber'}
                    labelKey={'inventory.fieldNumber.label'}
                    defaultValue={get(defaultValues, 'fieldNumber')}
                    value={editValues.fieldNumber}
                    onChange={handleChange}
                    disabled={isSaving}
                    fullWidth
                    required
                    margin={'none'}
                  />
                </Grid>
              )}
              <Grid
                item
                fullWidth
                xs={12}
                sm={isPlayground ? 6 : 12}
                md={isPlayground ? 6 : 12}
                lg={4}
              >
                <TextField
                  name={'description'}
                  labelKey={'inventory.description.label'}
                  defaultValue={get(defaultValues, 'description')}
                  value={editValues.description}
                  onChange={handleChange}
                  disabled={isSaving}
                  fullWidth
                  required
                  margin={'none'}
                />
              </Grid>
              {isPlayground && (
                <Fragment>
                  <Grid item fullWidth xs={12} sm={6} lg={4} xl={4}>
                    <ReactSelect
                      name={'ageAppropriatenessId'}
                      label={
                        <FHGTypography
                          display={'inline'}
                          id={'inventory.ageAppropriateness.label'}
                        />
                      }
                      options={ageAppropriatenessOptions}
                      defaultValue={defaultValues.ageAppropriatenessId}
                      value={editValues.ageAppropriatenessId}
                      isLoading={loadingAge}
                      hideSelectedOptions
                      // openMenuOnClick={false}
                      onMenuOpen={() => setIsSelectOpen(true)}
                      onMenuClose={() => setIsSelectOpen(false)}
                      isCreatable
                      isDeletable
                      isClearable
                      onDelete={handleAgeDelete}
                      backspaceRemovesValue
                      onChange={handleSelectAgeChange}
                      isMulti={false}
                      fullWidth
                      margin='none'
                      disabled={isSaving}
                      closeMenuOnScroll
                    />
                  </Grid>
                  <Grid item fullWidth xs={12} sm={6} lg={4} xl={4}>
                    <ReactSelect
                      name={'colorIdList'}
                      label={
                        <FHGTypography
                          display={'inline'}
                          id={'inventory.color.label'}
                        />
                      }
                      options={colorOptions}
                      defaultValue={colorDefaultValues}
                      value={colorValues}
                      isLoading={loadingColor}
                      hideSelectedOptions
                      // openMenuOnClick={false}
                      onMenuOpen={() => setIsSelectOpen(true)}
                      onMenuClose={() => setIsSelectOpen(false)}
                      isCreatable
                      isDeletable
                      isClearable
                      onDelete={handleColorDelete}
                      backspaceRemovesValue
                      onChange={handleSelectChanges}
                      isMulti
                      fullWidth
                      margin='none'
                      disabled={isSaving}
                      closeMenuOnScroll
                    />
                  </Grid>
                  <Grid item fullWidth xs={12} sm={6} lg={4} xl={4}>
                    <KeyboardDatePickerFHG
                      name={'installDate'}
                      labelKey={'inventory.installedDate.label'}
                      defaultValue={defaultValues.installDate}
                      value={editValues.installDate}
                      onChange={handleChange}
                      disabled={isSaving}
                      margin={'none'}
                      fullWidth
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
            <Grid item fullWidth className={classes.buttonPanelStyle}>
              <ProgressButton
                isProgress={isSaving}
                variant='contained'
                color='primary'
                type={'submit'}
                size={'small'}
                labelKey={'save.label'}
                disabled={isSaving}
              />
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
