import React, { useContext, useEffect, useRef, useState } from 'react';
import MapFHG from '../../fhg/components/map/MapFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {
  Box,
  Button,
  makeStyles,
  useTheme,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { ZOOM_DEFAULT } from '../../Constants';
import { get, over } from 'lodash';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import { PROPERTY_QUERY } from '../property/Property';
import ReactSelect from '../../fhg/components/ReactSelect';
import { MASTER_EQUIPMENT_REDUCED_INVENTORY_QUERY } from '../inventory/InventoryItemEdit';
import gql from 'graphql-tag';
import { formatMessage } from '../../fhg/utils/Utils';
import { useIntl } from 'react-intl';
import { ArrowBack, Delete, Edit } from '@material-ui/icons';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import DrawerContext from '../../components/DrawerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  button: {
    height: '50px',
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: '500',
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  textField: {
    width: '100%',
    height: '50px',
    '& .MuiInputBase-root': {
      height: '50px',
    },
  },
  selector: {
    flexWrap: 'nowrap',
  },
  selectorWrap: {
    flexWrap: 'wrap',
  },
}));

const DEFAULT = { lat: 36.231265, lng: -115.110922 };

const INVENTORY_LOCATION_ALL_QUERY = gql`
  query inventoryLocation_AllWhere($inventoryLocationSearch: InventoryLocationSearchInput) {
    inventoryLocation_AllWhere(inventoryLocationSearch: $inventoryLocationSearch) {
      id
      clientId
      inventoryItemId
      equipmentId
      propertyId
      name
      description
      latitude
      longitude
      equipment {
        areaId
        area {
          id
          pinType
        }
      }
    }
  }
`;

const INVENTORY_LOCATION_DELETE = gql`
  mutation inventoryLocation_Delete($inventoryLocationId: Int!) {
    inventoryLocation_Delete(inventoryLocationId: $inventoryLocationId)
  }
`;

const INVENTORY_LOCATION_UPDATE = gql`
  mutation inventoryLocation_Update(
    $inventoryLocationId: Int!
    $inventoryLocation: InventoryLocationUpdateInput!
  ) {
    inventoryLocation_Update(
      inventoryLocationId: $inventoryLocationId
      inventoryLocation: $inventoryLocation
    ) {
      id
    }
  }
`;

const ShowAssets = ({ match }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [firstCenter, setFirstCenter] = useState(DEFAULT);

  const intl = useIntl();

  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = React.useState(false);

  const [multi, setMulti] = useState(false);

  const currentAsset = useRef(null);
  const [name, setName] = useState('');

  const { isDrawerOpen } = useContext(DrawerContext);

  useEffect(() => {
    document.title = formatMessage(intl, 'inspect.show.assets.button.label');
  }, []);

  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState();
  const [assetType, setAssetType] = useState(null);

  const handleSelectChange = (value) => {
    setAssetType(value);
  };

  const { loading: loadingMasterEquipment, data: dataMasterEquipment = [] } = useQueryFHG(
    MASTER_EQUIPMENT_REDUCED_INVENTORY_QUERY,
    { fetchPolicy: 'cache-and-network' },
  );

  const handleShow = () => {
    setAsset();
    setAssets(
      (inventoryLocation_AllWhere || [])
        .filter(
          (item) =>
            !assetType ||
            (Array.isArray(assetType)
              ? assetType.map((item) => item.value).includes(item.equipmentId)
              : item.equipmentId === assetType.value),
        )
        .map((item) => ({
          id: item.id,
          name: item.name,
          lng: item.longitude,
          lat: item.latitude,
          pinType: item.equipment?.area?.pinType,
        })) || [],
    );
  };

  const id = Number(get(match, 'params.id', 0));

  const { data: dataInventory, refetch } = useQueryFHG(INVENTORY_LOCATION_ALL_QUERY, {
    variables: {
      inventoryLocationSearch: {
        propertyId: id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { inventoryLocation_AllWhere } = dataInventory || {};

  const { data } = useQueryFHG(PROPERTY_QUERY, {
    variables: { id },
    fetchPolicy: 'cache-first',
  });

  const property = get(data, 'property');

  useEffect(() => {
    if (property) {
      setFirstCenter({ lat: property.latitude, lng: property.longitude });
    }
  }, [property]);

  const [deleteInventoryLocation] = useMutationFHG(INVENTORY_LOCATION_DELETE);
  const [updateInventoryLocation] = useMutationFHG(INVENTORY_LOCATION_UPDATE);

  const handleDeleteAsset = async () => {
    try {
      setShowModalDelete(false);
      await deleteInventoryLocation({
        variables: {
          inventoryLocationId: currentAsset.current.id,
        },
      });
      await refetch();
      setAssets((prev) => prev.filter((item) => item.id !== currentAsset.current.id));
      setAsset();
      //
    } catch (error) {
      console.error('Error deleting inventory location', error);
    }
  };

  const handleEditAsset = async () => {
    try {
      setShowModalEdit(false);
      await updateInventoryLocation({
        variables: {
          inventoryLocationId: currentAsset.current.id,
          inventoryLocation: {
            name,
          },
        },
      });
      await refetch();
      setAsset([{ ...currentAsset.current, name }]);
      setName('');
      setAssets((prev) =>
        prev.map((item) => {
          if (item.id === currentAsset.current.id) {
            return { ...item, name };
          }
          return item;
        }),
      );
    } catch (error) {}
  };

  return (
    <Grid container item className={classes.root} wrap={'nowrap'} direction={'column'}>
      {isDrawerOpen && (
        <Grid
          container
          item
          style={{
            width: '20%',
            backgroundColor: theme.palette.background.paper,
            marginRight: '10px',
          }}
        >
          <Box
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                height: '100%',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box mb={2} display="flex" alignItems="center">
                  <IconButton href={`/property/${id}`}>
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h6">Show Assets</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  <Checkbox
                    checked={multi}
                    color="primary"
                    onChange={(e) => setMulti(e.target.checked)}
                  />
                  <Typography
                    variant={'body1'}
                    style={{
                      color: '#000000',
                    }}
                    id={'multi.select.assets'}
                  />
                </Box>
                <Box>
                  <ReactSelect
                    classes={{
                      valueContainer: multi ? classes.selectorWrap : classes.selector,
                    }}
                    label={<Typography display="inline" id="show.asset.type" />}
                    options={dataMasterEquipment?.masterEquipment || []}
                    value={assetType}
                    onChange={handleSelectChange}
                    isClearable
                    fullWidth
                    isMulti={multi}
                  />
                </Box>
                <Button
                  disabled={loadingMasterEquipment}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleShow}
                  style={{
                    marginTop: '24px',
                  }}
                >
                  <Typography
                    variant={'body1'}
                    style={{
                      color: '#ffffff',
                    }}
                    id={'inspect.show.assets.button.label'}
                  />
                </Button>
                <Box mt={3}>
                  <Typography id={'Inventory List'} className={classes.title} />
                </Box>
              </Box>
              <Box
                style={{
                  flexGrow: 1,
                  overflow: 'auto',
                }}
              >
                <List
                  style={{
                    width: '100%',
                  }}
                >
                  {assets?.map((item) => (
                    <ListItem
                      key={item.id}
                      component={Button}
                      onClick={() => {
                        setAsset([item]);
                      }}
                      style={{
                        borderBottom: '1px solid #e0e0e0',
                        display: 'flex',
                      }}
                    >
                      <Box
                        display={'flex'}
                        style={{
                          wordBreak: 'break-all',
                          flexGrow: 1,
                        }}
                      >
                        <ListItemText className={classes.title} primary={item.name} />
                      </Box>
                      <Box display={'flex'}>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            currentAsset.current = item;
                            setShowModalEdit(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            currentAsset.current = item;
                            setShowModalDelete(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
      <Box
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          padding: '10px',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <MapFHG
          name="map"
          defaultCenter={asset?.[0] || assets?.[0] || firstCenter}
          defaultZoom={ZOOM_DEFAULT}
          markers={assets}
          hideCenter
        />
      </Box>
      <ModalDialog
        open={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        messageKey="modal.delete.asset.title"
        submitKey={'inspect.yesAnswer.menuItem'}
        cancelKey={'inspect.noAnswer.menuItem'}
        onSubmit={handleDeleteAsset}
        submitColor="primary"
        variantSubmit="contained"
        variantCancel="contained"
      />
      <ModalDialog
        open={showModalEdit}
        onClose={() => setShowModalEdit(false)}
        messageKey="modal.edit.asset.title"
        submitKey={'add.edit'}
        cancelKey={'cancel.button'}
        onSubmit={handleEditAsset}
        submitColor="primary"
        variantSubmit="contained"
        variantCancel="contained"
      >
        <TextField
          defaultValue={currentAsset.current?.name}
          onChange={(e) => setName(e.target.value)}
        />
      </ModalDialog>
    </Grid>
  );
};

export default ShowAssets;
